<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 10px 5px 10px 20px;">
      <div style="background-color: white;">
    <v-data-table
      :headers="headers"
      :items="devices"
      :items-per-page="-1"
      class="elevation-0"
      hide-default-footer
    >      
      <template 
        v-slot:top 
      >
      <div
        style="text-align: right; padding: 20px 20px 5px 5px;width: 100%;"
      >
        <v-btn 
          @click="handleGetData"
        >
          refrescar
        </v-btn>
      </div>
      </template>
      <template v-slot:item.ident="{ item }">
        <a @click="editedItem = item">
          {{item.Name}}
          <br/>
          <small>{{item.ID}}</small>
        </a>
      </template>
      <template v-slot:item.LCD="{item}">
        {{getDate(item.LCD)}}
      </template>
    </v-data-table>
    <!--player-form /-->
      </div>
    </v-flex>
    <v-flex xs4
        style="padding: 10px;"
      >
      <div
        style="padding: 10px;background-color:white;min-height: calc(-100px + 100vh);"
      >
      <v-btn 
        v-if="!editedItem"
        @click="handleAddItem"
        block
        color="primary"
      >
        a�adir
      </v-btn>
      <chromecast-form
        v-if="editedItem"
        :editedItem="editedItem"
        :onClose="handleClose"
      />
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import api from '@/services/api'
import ChromecastForm from './ChromecastForm'
export default {
  components: {
    ChromecastForm,
  },
  data: () => ({
    devices: [],
    editedItem: null,
  }),
  computed: {
    environment () {
      return 'players'
    },
    headers () {
      return [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'ident',
        },
        //{ text: 'LCD', value: 'LCD' },
      ]
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getDate (v) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

      return v.toLocaleDateString('es', options)
    },
    handleGetData () {
      const url = 'v1/private/chromecast/'
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          this.devices = response.map(item => {
            item.ident = {
              ID: item.ID,
              Name: item.Name,
            }
            //item.LCD = item.LCD ? new Date(item.LCD) : null
            return item
          })
        })
    },
    handleAddItem () {
      this.editedItem = {
        ID: null,
        Name: '',
        Debug: false,
        Parrilla: null,
        Banner: null,
        Message: null,
        BGColor: '#000000',
        Color: '#FFFFFF',
        BGColorMessage: '#000000',
        ColorMessage: '#FFFFFF',
        BGColorParrilla: '#000000',
        BGColorBanner: '#000000',
        Time: 15,
      }
    },
    handleClose (refresh) {
      if (refresh) this.handleGetData() 
      this.editedItem = null
    },
  },
}
</script>

